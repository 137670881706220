<template>
  <!-- Overlay -->
  <div
    v-show="isOverlayOpen"
    ref="overlayBack"
    class="buttons-overlay"
    @click="handleBackdropClick"
  >
    <div ref="overlay" class="buttons-overlay__content">
      <h2
        class="title-xxs text-style-uppercase"
        v-html="$t('general.download-overlay-title', { month: monthName })"
      />

      <div
        class="buttons-overlay__button"
        v-for="event in props.events"
        :key="event.id"
      >
        <nuxt-link
          :to="event.file?.url || event.link"
          class="button-round-1 --bordered"
        >
          <span>{{ event.label }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            aria-labelledby="downloadIconTitle"
            stroke="#2329D6"
            stroke-width="1.5"
            stroke-linecap="round"
            fill="none"
            color="#2329D6"
          >
            <path d="M12 3v13M7 12l5 5 5-5M20 21H4" />
          </svg>
        </nuxt-link>
      </div>

      <button
        class="buttons-overlay__close link-underline-1 m-top-32px"
        @click="closeOverlay"
      >
        <span>{{ $t('general.download-overlay-close') }}</span>
      </button>
    </div>
  </div>

  <!-- Button -->
  <button
    v-show="!isOverlayOpen"
    ref="button"
    @click="openOverlay"
    class="buttons-overlay__trigger --green button-round-1"
  >
    <span>{{ $t('general.download-overlay-trigger') }}</span>
  </button>
</template>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  events: Array,
})

const isOverlayOpen = ref(true)
const { locale } = useI18n()
const monthName = ref(null)
const overlayBack = ref(null)
const overlay = ref(null)
const button = ref(null)

const openOverlay = () => {
  isOverlayOpen.value = true
  gsap.fromTo(
    overlay.value,
    { opacity: 0 },
    { opacity: 1, duration: 0.5, ease: 'power2.out' }
  )
}

const closeOverlay = () => {
  gsap.to(overlay.value, {
    opacity: 0,
    duration: 0.5,
    ease: 'power2.inOut',
    onComplete: () => {
      isOverlayOpen.value = false
    },
  })
}

const handleBackdropClick = (event) => {
  if (event.target === overlayBack.value) {
    closeOverlay()
  }
}

onMounted(() => {
  const date = new Date()
  const formatter = new Intl.DateTimeFormat(locale.value, { month: 'long' })
  monthName.value = formatter.format(date)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
