<template>
  <WidgetNavigationBar :fixed="openOverlay">
    <nav class="nav-menu" :class="{ '--open': openOverlay }">
      <!-- Hamburger -->
      <div class="nav-menu__mobile hide-for-large">
        <!-- Hamburger -->
        <WidgetHamburger
          class="nav-menu__hamburger"
          @click="toggleOverlay"
          ref="hamburger"
        />
      </div>

      <!-- Logo -->
      <div class="nav-menu__logo">
        <nuxt-link :to="localePath({ name: 'index' })">
          <img
            src="/assets/img/logo-mazarol.svg"
            width="55"
            height="60"
            alt="Cooperativa Mazarol"
          />
        </nuxt-link>
      </div>

      <!-- Nav -->
      <div class="nav-menu__menu show-for-large">
        <ul>
          <li
            v-for="page in navigation"
            :key="page.id"
            @mouseenter="mouseEnter(page.slug)"
            @mouseleave="mouseLeaveSubMenu"
          >
            <nuxt-link
              :to="
                localePath({
                  name: 'activity-parent',
                  params: { parent: page.slug },
                })
              "
              class="nav-menu__link"
              >{{ page.pageTitle }}
              <img
                v-if="page.childSections.length"
                src="/assets/img/ico/ico-down.svg"
                width="22"
                height="22"
                alt="Open submenu"
            /></nuxt-link>
            <div
              v-if="page.childSections.length"
              :class="{ '--is-open': openSubmenu && showSubmenu === page.slug }"
              class="nav-menu__dropdown"
            >
              <ul>
                <li v-for="subpage in page.childSections" :key="subpage.id">
                  <nuxt-link
                    :to="
                      localePath({
                        name: 'activity-parent-slug',
                        params: { parent: page.slug, slug: subpage.slug },
                      })
                    "
                    class="nav-menu__link__small"
                    >{{ subpage.pageTitle }}</nuxt-link
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <!-- Newsletter -->
      <div class="nav-menu__newsletter">
        <nuxt-link
          :to="
            localePath({
              name: 'newsletter',
            })
          "
          class="button-round-1"
        >
          <span>{{ $t('navigation.newsletter') }}</span>
        </nuxt-link>
      </div>
    </nav>
  </WidgetNavigationBar>
  <!-- Overlay -->
  <WidgetOverlayFullScreen
    class="nav-menu__overlay hide-for-large"
    ref="overlay"
  >
    <div class="nav-menu__overlay__wrap" data-lenis-prevent>
      <ul>
        <li v-for="page in navigation" :key="page.id">
          <nuxt-link
            :to="
              localePath({
                name: 'activity-parent',
                params: { parent: page.slug },
              })
            "
            class="nav-menu__link"
            >{{ page.pageTitle }}</nuxt-link
          >
          <ul>
            <li v-for="subpage in page.childSections" :key="subpage.id">
              <nuxt-link
                :to="
                  localePath({
                    name: 'activity-parent-slug',
                    params: { parent: page.slug, slug: subpage.slug },
                  })
                "
                class="nav-menu__link__small"
                >{{ subpage.pageTitle }}</nuxt-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </WidgetOverlayFullScreen>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  navigation: Array,
})

const route = useRoute()

const hamburger = ref(null)
const overlay = ref(null)
const showSubmenu = ref(null)
const openSubmenu = ref(false)

const openOverlay = ref(false)

const tl = ref(null)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}

watch(
  () => openOverlay.value,
  (openOverlay) => {
    tl.value = openOverlay
      ? animateOverlayIn().duration(0.8)
      : animateOverlayOut().duration(0.8)
    tl.value.play()
  }
)
watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
  }
)

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,

    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(hamburger.value.animateIn().play())
  tl.add(overlay.value.animateIn().play(), '<')

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(hamburger.value.animateOut().play())
  tl.add(overlay.value.animateOut().play(), '<')

  return tl
}

const mouseEnter = (submenuCat) => {
  showSubmenu.value = submenuCat
  openSubmenu.value = true
}

const mouseLeaveSubMenu = () => {
  openSubmenu.value = false
  showSubmenu.value = null
}
</script>

<style lang="scss">
@import './style.scss';
</style>
