<template>
  <div class="marquee">
    <div class="marquee__inner">
      <div class="marquee__part">
        Guide Naturalistico-ambientali Regionali - Guide Nazionali Aigae -
        Marchio di Qualità del Parco Nazionale Dolomiti Bellunesi per Educazione
        Ambientale e Escursionismo - Guide Accreditate con Veneto Agricoltura
        per Escursionismo e Educazione Ambientale - Soci Consorzio Turistico
        Dolomiti Prealpi Soci Consorzio Promozione Turistica Alpago e Cansiglio
      </div>
      <div class="marquee__part">
        Guide Naturalistico-ambientali Regionali - Guide Nazionali Aigae -
        Marchio di Qualità del Parco Nazionale Dolomiti Bellunesi per Educazione
        Ambientale e Escursionismo - Guide Accreditate con Veneto Agricoltura
        per Escursionismo e Educazione Ambientale - Soci Consorzio Turistico
        Dolomiti Prealpi Soci Consorzio Promozione Turistica Alpago e Cansiglio
      </div>
      <div class="marquee__part">
        Guide Naturalistico-ambientali Regionali - Guide Nazionali Aigae -
        Marchio di Qualità del Parco Nazionale Dolomiti Bellunesi per Educazione
        Ambientale e Escursionismo - Guide Accreditate con Veneto Agricoltura
        per Escursionismo e Educazione Ambientale - Soci Consorzio Turistico
        Dolomiti Prealpi Soci Consorzio Promozione Turistica Alpago e Cansiglio
      </div>
      <div class="marquee__part">
        Guide Naturalistico-ambientali Regionali - Guide Nazionali Aigae -
        Marchio di Qualità del Parco Nazionale Dolomiti Bellunesi per Educazione
        Ambientale e Escursionismo - Guide Accreditate con Veneto Agricoltura
        per Escursionismo e Educazione Ambientale - Soci Consorzio Turistico
        Dolomiti Prealpi Soci Consorzio Promozione Turistica Alpago e Cansiglio
      </div>
      <div class="marquee__part">
        Guide Naturalistico-ambientali Regionali - Guide Nazionali Aigae -
        Marchio di Qualità del Parco Nazionale Dolomiti Bellunesi per Educazione
        Ambientale e Escursionismo - Guide Accreditate con Veneto Agricoltura
        per Escursionismo e Educazione Ambientale - Soci Consorzio Turistico
        Dolomiti Prealpi Soci Consorzio Promozione Turistica Alpago e Cansiglio
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetMarquee',
}
</script>

<script setup>
import gsap from 'gsap'

onMounted(() => {
  let currentScroll = 0
  let isScrollingDown = true

  let tween = gsap
    .to('.marquee__part', {
      xPercent: -100,
      repeat: -1,
      duration: 40,
      ease: 'linear',
    })
    .totalProgress(0.5)

  gsap.set('.marquee__inner', { xPercent: -50 })

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > currentScroll) {
      isScrollingDown = true
    } else {
      isScrollingDown = false
    }

    gsap.to(tween, {
      timeScale: isScrollingDown ? 1 : -1,
    })

    currentScroll = window.pageYOffset
  })
})
</script>

<style lang="scss">
@import './style.scss';
</style>
