<template>
  <section class="footer-loghi">
    <div class="row-1 p-top-2 p-bottom-1">
      <header class="footer-loghi__header">
        <h2 class="text-style-center title-s">
          {{ $t('footer.loghi-title') }}
        </h2>
        <p class="text-m m-top-16px text-style-center">
          {{ $t('footer.loghi-text') }}
        </p>
      </header>
      <div class="m-top-3 footer-loghi__grid">
        <div
          v-for="logo in logos"
          :key="logo.id"
          class="footer-loghi__item"
          :class="{
            '--portrait':
              logo.image.responsiveImage.width <
              logo.image.responsiveImage.height,
            '--landscape':
              logo.image.responsiveImage.width >=
              logo.image.responsiveImage.height,
          }"
        >
          <a :href="logo.link">
            <img
              :src="logo.image.responsiveImage.src"
              :width="logo.image.responsiveImage.width"
              :height="logo.image.responsiveImage.height"
              :alt="logo.link"
            />
            <p class="footer-loghi__text" v-if="logo.text">{{ logo.text }}</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterLoghi',
}
</script>

<script setup>
const props = defineProps({
  logos: Array,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
