<template>
  <aside class="footer-newsletter p-top-2">
    <div class="row-1">
      <div class="footer-newsletter__wrap row-1">
        <div class="footer-newsletter__content">
          <h2
            class="text-style-center title text-style-uppercase"
            v-html="$t('footer.newsletter-title')"
          />
          <p class="text-m m-top-16px text-style-center">
            {{ $t('footer.newsletter-text') }}
          </p>

          <nuxt-link
            :to="
              localePath({
                name: 'newsletter',
              })
            "
            class="button-round-1 --orange m-top-24px"
          >
            <span>{{ $t('footer.newsletter-cta') }}</span>
          </nuxt-link>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'FooterNewsletter',
}
</script>

<script setup></script>

<style lang="scss">
@import './style.scss';
</style>
