<template>
  <main class="layout-default">
    <SeoTemplatePreset>
      <WidgetButtonsOverlay
        v-if="showButtons && events.length"
        :events="events"
      />

      <NavigationMenu :navigation="navigation" />
      <slot />
      <FooterNewsletter />
      <FooterLoghi :logos="footer.logos" />
      <div class="p-top-3 p-bottom-3 layout-default__footer">
        <WidgetMarquee class="text-style-uppercase text-xl f-family-2" />
        <FooterContacts class="m-top-3" />
      </div>
    </SeoTemplatePreset>
  </main>
</template>

<script setup>
import { useStore } from '@/store/index.js'

import queries from '@/graphql/queries/index.js'

const { locale } = useI18n()
const store = useStore()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(queries.global.getGlobal, variables)

const footer = computed(() => data.value?.footer)
const navigation = computed(() => data.value?.allSections)
const events = computed(() => data.value?.allEventDownloads)
const showButtons = ref(false)

onMounted(() => {
  setTimeout(() => {
    showButtons.value = true
  }, 250)
})

watchEffect(() => {
  store.setState({
    navigation,
    events,
  })
})
</script>
